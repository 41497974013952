<template>
  <b-sidebar
    id="add-new-user-sidebar"
    ref="add_interview_sidebar"
    :visible="isAddNewInterviewSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-interview-sidebar-active', val)"
    no-close-on-backdrop
  >
    <template #default="{ hide }">
      <b-link class="text-primary floating-close" @click="confirmCloseButton">
        <feather-icon icon="XCircleIcon" size="26" class="mr-25" />
      </b-link>
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Interview</h5>

        <!-- <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        /> -->
      </div>

      <!-- BODY -->
      <validation-observer ref="refFormObserver">
        <!-- Form -->
        <b-form autocomplete="off" id="resume-form" class="p-2" @reset.prevent="resetForm">
          <validation-provider
            #default="validationContext"
            name="Interview Title"
            rules="required"
          >
            <b-form-group>
              <label class="required-l" for="interview-title"
                >Interview Title</label
              >
              <b-form-input
                id="interview-title"
                v-model="interviewData.title"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Meeting Agenda"
            rules="required"
          >
            <b-form-group>
              <label class="required-l" for="agenda">Meeting Agenda</label>
              <b-form-textarea
                id="agenda"
                v-model="interviewData.agenta"
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group>
            <label class="required-l">Start Date & Time</label>
            <div class="d-flex align-items-center">
              <flat-pickr
                v-model="interviewData.start_at"
                :config="{
                  enableTime: true,
                  dateFormat: 'Y-m-d H:i',
                  altFormat: 'M j, h:iK',
                  altInput: true,
                }"
                class="form-control"
                @input="syncEndDate"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <label class="required-l">End Date & Time</label>
            <div class="d-flex align-items-center">
              <flat-pickr
                v-model="interviewData.end_at"
                :config="{
                  enableTime: true,
                  dateFormat: 'Y-m-d H:i',
                  altFormat: 'M j, h:iK',
                  altInput: true,
                }"
                class="form-control"
              />
              <!-- <vue-timepicker
                class="col-6"
                format="hh:mm A"
                :minute-interval="5"
                v-model="interviewData.end_time"
                manual-input
                close-on-complete
              ></vue-timepicker> -->
            </div>
          </b-form-group>

          <b-form-group>
            <label class="required-l" for="select-candidate"
              >Select Candidate</label
            >
            <b-form-input
              v-if="candidate.name"
              id="select-candidate"
              v-model="candidate.name"
              :disabled="true"
            />
            <v-select
              v-else
              v-model="interviewData.candidate_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="candidateData"
              :reduce="(label) => label.code"
              label="label"
              :clearable="true"
              input-id="select-candidate"
              placeholder="Select Candidate.."
            />
            <!-- :disabled="candidateChangeFlag" -->
          </b-form-group>

          <b-form-group>
            <label class="required-l" for="assign-interviewers"
              >Assign Interviewers</label
            >
            <v-select
              v-model="interviewData.interviewers"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="interviewersData"
              :reduce="(label) => label.code"
              label="label"
              :multiple="true"
              :clearable="true"
              id="assign-interviewers"
              placeholder="Select Interviewers.."
            />
          </b-form-group>
          <b-form-group>
            <label class="required-l" for="video-conferencing"
              >Add video conference</label
            >
            <v-select
              v-model="video_conferencing"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="[
                { id: 1, label: 'No', value: 'no' },
                { id: 2, label: 'Meet', value: 'meet' },
                { id: 3, label: 'Zoom', value: 'zoom' },
              ]"
              :reduce="(label) => label.value"
              label="label"
              input-id="video-conferencing"
              placeholder="Select Type.."
            >
            </v-select>
          </b-form-group>

          <b-form-group
            v-if="video_conferencing == 'zoom' && zoom_access == false"
          >
            <button
              label-for="zoom-password"
              type="button"
              class="btn btn-info mt-1"
              style="height: 40px"
              @click="loginWithZoom"
            >
              Login Zoom
            </button>
          </b-form-group>

          <b-form-group v-if="video_conferencing == 'zoom'">
            <label class="required-l" for="zoom-password">Zoom Password</label>
            <b-form-input
              id="zoom-password"
              v-model="interviewData.password"
              autofocus
              trim
            />
          </b-form-group>

          <validation-provider
            #default="validationContext"
            name="Meeting Details"
            rules="required"
          >
            <b-form-group>
              <label class="required-l" for="meeting-details"
                >Meeting Details</label
              >
              <b-form-textarea
                id="meeting-details"
                v-model="interviewData.meeting_details"
                trim
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Add Poster -->

          <b-form-group
            label="Upload Zoom Meeting Poster ( less than 3MB)"
            label-for="files"
          >
            <div v-if="!interviewData.poster">
              <file-pond
                ref="pond"
                name="poster"
                max-file-size="3MB"
                max-files="1"
                accepted-file-types="image/*"
                allow-multiple="false"
                image-preview-height="120px"
              />
              <small class="text-warning flex items-center">
                <feather-icon icon="AlertCircleIcon" class="mr-25" />
                Allowed formats are
                <b>.jpeg, .png</b>
              </small>
            </div>

            <div
              v-else
              class="d-flex align-items-center justify-content-around"
            >
              <img
                :src="interviewData.poster"
                style="height: 100px; object-fit: contain"
              />
              <button
                type="button"
                class="btn btn-outline-danger btn-sm"
                style="height: 40px"
                @click="
                  () => {
                    interviewData.poster = false;
                  }
                "
              >
                Delete Poster
              </button>
            </div>
          </b-form-group>

          <!-- Add Task -->

          <b-form-group label="Upload Task ( less than 3MB)" label-for="task">
            <file-pond
              v-if="!interviewData.task"
              ref="task"
              name="task"
              max-file-size="3MB"
              max-files="1"
              allow-multiple="false"
              accepted-file-types="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,.pps,.pptx"
              file-validate-type-label-expected-types="Expects a PDF, DOC or DOCX,PPT file"
            />

            <div
              v-else
              class="d-flex align-items-center justify-content-around"
              style="padding: 10px; border-radius: 10px; background: #f1f0ef"
            >
              <div class="d-flex align-items-center">
                <feather-icon
                  class="cursor-pointer mr-25"
                  icon="FileIcon"
                  size="16"
                />
                Task Exists
              </div>
              <button
                type="button"
                class="btn text-danger btn-sm"
                style="height: 40px; border: solid red 1px"
                @click="
                  () => {
                    interviewData.task = false;
                  }
                "
              >
                Delete task
              </button>
            </div>
            <small class="text-warning flex items-center">
              <feather-icon icon="AlertCircleIcon" class="mr-25" />
              Allowed formats are
              <b>.pdf, .doc, .docx, .pptx</b>
            </small>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              :disabled="is_loading"
              @click="validationForm"
            >
              Submit
              <span
                class="spinner-border text-light spinner-border-sm ml-1"
                v-if="is_loading"
              ></span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              
              @click="hide() + closeModal()"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
      <b-overlay :show="is_loading" no-wrap> </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  VBTooltip,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { onMounted, ref, watch } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import VueTimepicker from "vue2-timepicker/src";

// file-pond dependencies
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";

import DropdownPicker from "../datepicker/dropdown-datepicker.vue";

import useJwt from "@/auth/jwt/useJwt";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
import moment from "moment";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,

    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
    VueTimepicker,
    FilePond,
    DropdownPicker,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  model: {
    prop: "isAddNewInterviewSidebarActive",
    event: "update:is-add-new-interview-sidebar-active",
  },
  props: {
    isAddNewInterviewSidebarActive: {
      type: Boolean,
      required: true,
    },
    interviewId: {
      type: String,
      required: false,
      default: "",
    },
    interviewData: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    candidate: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    candidateChangeFlag: {
      type: Boolean,
      required: false,
      default() {
        return true;
      },
    },
  },
  data() {
    return {
      newUserData: this.$cookies.get("userData"),
      is_loading: false,
      required,
      zoom_access: this.checksAccessToken(),
      candidateData: [],
      interviewersData: [],
      video_conferencing: "no",
    };
  },
  setup() {
    const blankInterviewData = {
      title: "",
      agenta: "",
      start_at: moment().format("YYYY-MM-DD HH:mm"),
      end_at: moment().add(1, "hour").format("YYYY-MM-DD HH:mm"),
      candidate_id: "",
      interviewers: "",
      meeting_details: "",
    };
    const interviewDetails = ref(
      JSON.parse(JSON.stringify(blankInterviewData))
    );

    const resetinterviewData = () => {
      interviewDetails.value = JSON.parse(JSON.stringify(blankInterviewData));
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetinterviewData);
    return {
      interviewDetails,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  watch: {
    video_conferencing: function (val) {
      if (val == "zoom") {
        this.updateUserInfo();
        if (!this.newUserData.zoom_linked) {
          console.log("no access token");
        }
      }
    },
    isAddNewInterviewSidebarActive: function (val) {
      // this.resetForm();
      this.getCandidateData();
      // this.getInterviewersData();
    },

    // latestStartTime: function (val) {
    // if (parseInt(val.mm) + 30 < 60) {
    //   this.interviewData.end_time.mm = parseInt(val.mm) + 30 + "";
    //   this.interviewData.end_time.hh = val.hh;
    // } else {
    //   if (parseInt(val.mm) == 30) {
    //     this.interviewData.end_time.hh = "0" + parseInt(val.hh) + 1 + "";
    //     console.log(this.interviewData.end_time.hh);

    //     this.interviewData.end_time.mm = "00";
    //     return;
    //   }
    //   this.interviewData.end_time.hh = parseInt(val.hh) + 1 + "";
    //   this.interviewData.end_time.mm = parseInt(val.mm) - 30 + "";
    // }
    // },
  },
  created() {
    this.$store
      .dispatch("app/fetchInterviewers")
      .then((res) => {
        this.interviewersData = res.data.data;
      })
      .catch((err) => {
        console.error(err);
      });
    this.getCandidateData();
  },
  mounted() {},
  methods: {
    syncEndDate() {
      this.interviewData.end_at = moment(this.interviewData.start_at)
        .add(1, "hour")
        .format("YYYY-MM-DD HH:mm");
    },
    confirmCloseButton() {
      this.$swal({
        title: "Are you sure?",
        text: "You are about to exit this form , if you proceed the form data will be lost.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$refs.add_interview_sidebar.hide();
        }
      });
    },
    openWindow(link) {
      window.open(link);
    },
    validationForm() {
      const self = this;

      if (
        self.checksAccessToken() == false &&
        self.video_conferencing == "zoom"
      ) {
        self.$toast({
          component: ToastificationContent,
          props: {
            title: "Please login with Zoom",
            icon: "BellIcon",
            variant: "danger",
            text: "Zoom login required for creating the interview meeting in zoom",
          },
        });
        return false;
      }

      //Getting Zoom Poster from local storage
      const formData = new FormData(document.getElementById("resume-form"));
      formData.delete("poster");
      formData.delete("task");
      var poster = [];
      var task = [];
      formData.append("video_conferencing", this.video_conferencing);
      if (this.interviewData.password) {
        formData.append("password", this.interviewData.password);
      }
      if (self.$refs.pond) {
        poster = self.$refs.pond.getFiles();
        if (poster[0]) {
          formData.append("poster", poster[0].file);
        }
      }

      if (self.$refs.task) {
        task = self.$refs.task.getFiles();
        if (task[0]) {
          formData.append("task", task[0].file);
        }
      }

      if (poster.length == 0 && this.interviewData.poster == false) {
        //delete poster even if exists in database
        formData.append("poster", "delete");
      }

      if (task.length == 0 && this.interviewData.task == false) {
        //delete task if exists in database
        formData.append("task", "delete");
      }

      if (
        self.interviewData.start_at === "" ||
        self.interviewData.end_at === "" ||
        typeof self.interviewData.start_at == "undefined" ||
        typeof self.interviewData.end_at == "undefined"
      ) {
        self.$toast({
          component: ToastificationContent,
          props: {
            title: "Date and Time field is required",
            icon: "BellIcon",
            variant: "danger",
            text: "Date fields are required for creating the interview",
          },
        });
        return false;
      }
      if (this.interviewData.candidate_id === "") {
        self.$toast({
          component: ToastificationContent,
          props: {
            title: "Candidate field is required",
            icon: "BellIcon",
            variant: "danger",
            text: "Candidate field are required for creating the interview",
          },
        });
        return false;
      }
      if (typeof self.interviewData.interviewers == "undefined") {
        self.$toast({
          component: ToastificationContent,
          props: {
            title: "Interviewers field is required",
            icon: "BellIcon",
            variant: "danger",
            text: "Interviewers field are required for creating the interview",
          },
        });
        return false;
      }

      if (this.interviewData.interviewers === "") {
        self.$toast({
          component: ToastificationContent,
          props: {
            title: "Interviewers field is required",
            icon: "BellIcon",
            variant: "danger",
            text: "Interviewers field are required for creating the interview",
          },
        });
        return false;
      }
      return self.$refs.refFormObserver.validate().then((success) => {
        if (success) {
          this.is_loading = true;
          //Setting dummy variable, remove once SQL query is updated
          formData.append("title", self.interviewData.title);
          formData.append("agenta", self.interviewData.agenta);
          formData.append(
            "meeting_details",
            self.interviewData.meeting_details
          );
          formData.append("start_at", self.interviewData.start_at);
          formData.append("end_at", self.interviewData.end_at);
          formData.append("interviewers", self.interviewData.interviewers);
          formData.append("candidate_id", self.interviewData.candidate_id);
          if (self.candidate.id) {
            formData.append("candidate_id", self.candidate.id);
          }

          

          if (self.interviewId === "") {
            self.$store
              .dispatch("app/addInterview", formData)
              .then((res) => {
                if (res.data.success) {
                  self.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Interview Added",
                      icon: "BellIcon",
                      variant: "success",
                      text: res.data.message,
                    },
                  });
                  self.$emit("refetch-data");
                  self.$emit(
                    "update:is-add-new-interview-sidebar-active",
                    false
                  );
                } else {
                  self.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Oops! Interview Adding Failed",
                      icon: "BellIcon",
                      variant: "danger",
                      text: res.data.message,
                    },
                  });
                }
              })
              .catch((error) => {
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Oops! Interview Adding Failed",
                    icon: "BellIcon",
                    variant: "danger",
                    text: error,
                  },
                });
                // self.$emit("refetch-data");
                // self.$emit(
                //   "update:is-add-new-interview-sidebar-active",
                //   false
                // );
              })
              .finally(() => (this.is_loading = false));
          } else {
            formData.append("id", self.interviewId);
            formData.append("_method", "PATCH");
            self.$store
              .dispatch("app-interviews/updateInterview", formData)
              .then((res) => {
                if (res.data.success) {
                  self.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Interview Updated",
                      icon: "BellIcon",
                      variant: "success",
                      text: res.data.message,
                    },
                  });
                  self.$emit("refetch-data");
                  self.$emit(
                    "update:is-add-new-interview-sidebar-active",
                    false
                  );
                } else {
                  self.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Oops! Interview updating Failed",
                      icon: "BellIcon",
                      variant: "danger",
                      text: res.data.message,
                    },
                  });
                }
              })
              .catch((error) => {
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Oops! Interview updating Failed",
                    icon: "BellIcon",
                    variant: "danger",
                    text: error,
                  },
                });
              })
              .finally(() => (this.is_loading = false));
          }
        } else {
          reject();
        }
      });
    },
    loginWithZoom() {
      const self = this;
      var url =
        "https://zoom.us/oauth/authorize?response_type=code&client_id=" +
        process.env.VUE_APP_ZOOM_CLIENT_ID +
        "&redirect_uri=" +
        process.env.VUE_APP_ZOOM_REDIRECT_URI +
        "&state=" +
        self.newUserData.hashid;

      if (!self.zoom_access || !self.newUserData.zoom_linked) {
        var win = window.open(url, "Google", "width=600,height=600");

        var timer = setInterval(function () {
          if (win.closed) {
            clearInterval(timer);
            useJwt.getUserInfo().then((res) => {
              //localStorage.setItem("userData", JSON.stringify(res.data.data));
              this.$cookies.set(
                "userData",
                res.data.data,
                60 * process.env.VUE_APP_LOGIN_TIMEOUT
              );
              self.newUserData = this.$cookies.get("userData");
              if (self.newUserData.zoom_linked) {
                self.zoom_access = true;
              }
            });
          }
        }, 1000);
      } else {
        self.zoom_access = true;
      }
    },
    updateUserInfo() {
      useJwt.getUserInfo().then((res) => {
        //localStorage.removeItem("userData");
        //localStorage.setItem("userData", JSON.stringify(res.data.data));
        this.$cookies.remove("userData");
        this.$cookies.set(
          "userData",
          res.data.data,
          60 * process.env.VUE_APP_LOGIN_TIMEOUT
        );
        this.newUserData = this.$cookies.get("userData");
        console.log("updated info");
      });
    },
    checksAccessToken() {
      if (this.$cookies.get("userData").zoom_linked === false) {
        return false;
      }
      return true;
    },
    closeModal() {
      this.is_loading = false;
    },
    getCandidateData() {
      this.$store
        .dispatch("app/fetchCandidates")
        .then((res) => {
          this.candidateData = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.vue__time-picker {
  width: 6em !important;
}
.vue__time-picker input.display-time {
  width: 100% !important;
  background: whitesmoke;
  height: 38px;
  border-radius: 5px;
  margin-left: 3px;
  color: grey;
}
.vue__time-picker .dropdown,
.vue__time-picker-dropdown {
  height: 14rem !important;
}
.vue__time-picker .dropdown .select-list,
.vue__time-picker-dropdown .select-list {
  height: 14rem !important;
}
select#year,
select#month,
select#day {
  padding: 5px 10px;
  background: #eaeaea;
  border: solid rgb(218, 218, 218) 0.5px;
  border-radius: 5px;
  margin-left: 5px;
}
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.filepond--credits {
  display: none;
}
.flatpickr-input {
  background: whitesmoke !important;
}
</style>
